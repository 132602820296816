.customSegment ion-segment-button {
  --color-checked: white;
  --indicator-color: #3880ff;
  --border-color: white;
}

.statusMsg {
  position: absolute;
  margin-top: -220px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}
