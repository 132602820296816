ion-content ion-toolbar {
  --background: translucent;
}

.tabsContainer {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
}

.card.main {
  background-color: transparent;
}

.noShadow {
  box-shadow: none;
}

.horizontal {
  display: flex;
  flex-direction: row;
}

.vertical {
  display: flex;
  flex-direction: column;
}

.cardPadding {
  padding: 16px;
}

.cardPaddingHorizontal {
  padding: 0px 16px;
}

.cardPaddingVertical {
  padding: 16px 0px;
}

.contentInfoBlock {
  flex: 1;
}

.cardPaddingTop {
  padding: 16px 0px 0px 16px;
}

.cardPaddingBottom {
  padding: 0px 16px 16px 16px;
}

.card.shadow {
  -webkit-box-shadow: 2px 4px 17px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 4px 17px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 4px 17px -3px rgba(0, 0, 0, 0.75);
}

.card.border {
  border: 1px solid #edecee;
}

.card.main * {
  color: white;
}

.title {
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
}

.card .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header {
  margin-bottom: 5px;
}

.text {
  font-size: 16px;
  font-weight: 300;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0px;
  padding-top: 10px;
}

span.big {
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 5px;
}

span.secondary {
  font-size: 14px;
  font-weight: 300;
}

.main .footer {
  background-color: #2649da;
  display: flex;
  justify-content: space-around;
}

.infoBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer .mediumBold {
  font-size: 24px;
  font-weight: 700;
}

.footer .mediumThin {
  font-size: 14px;
  font-weight: 300;
  padding-left: 3px;
}

.infoBlock .infoBoxFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.infoBlock .subtitle {
  font-size: 12px;
  font-weight: 500;
}

.button.alternative {
  width: fit-content;
  padding: 8px 30px;
  border: 1px solid #3168ff;
  border-radius: 6px;
}

.summaryItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.summaryItemSpace.separator {
  border-bottom: 1px solid #eeeeee;
}
.summaryItemSpace {
  margin-bottom: 3px;
  padding-bottom: 3px;
}

.flexCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.summaryIconWrapper {
  display: flex;
  border-radius: 3px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.headerCard {
  margin: 0px;
  padding: 24px 16px;
  border-radius: 0px;
}

.headerBackground {
  background-color: rgba(248, 128, 15, 1);
  background-image: url('./../img/header_background.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

.blocksContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: -32px;
}

.cintac.separator {
  border-color: #e4e9f2;
}
