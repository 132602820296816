h1.cintac {
  color: #222b45;
  font-size: 36px;
  line-height: 48px;
  padding: 0px;
  margin: 0px;
}

h4.cintac {
  color: #222b45;
  font-size: 22px;
  line-height: 32px;
  padding: 0px;
  margin: 0px;
}

h5.cintac {
  color: #222b45;
  font-size: 18px;
  line-height: 24px;
  padding: 0px;
  margin: 0px;
}

h6.cintac {
  color: #222b45;
  font-size: 15px;
  line-height: 24px;
  padding: 0px;
  margin: 0px;
}

p.cintac {
  color: #222b45;
  font-size: 15px;
  line-height: 20px;
  padding: 0px;
  margin: 0px;
}

p.cintac.hint {
  color: #8f9bb3;
}

.cintac.white {
  color: white;
}

.regular {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.infoBackground {
  background-color: #f7f9fc;
}

p.cintac.small {
  font-size: 12px;
  line-height: 16px;
}

.clearButton.cintac {
  padding-left: 0px;
  padding-right: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  margin: 0px;
}

.cintac.ratioBar {
  overflow: hidden;
  height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.cintac.ratioSmallBar {
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.cintac.comparationItem {
  display: flex;
  flex-direction: row;
  padding-top: 12px;
}

.cintac.electricBlue {
  color: #3366ff;
}

.cintac.darkGray {
  color: #838185;
}
.cintac.darkGrayBackground {
  background-color: #838185;
}

.cintac.lightBlueBackground {
  background-color: #d2d9ec;
}

.cintac.darkBlue {
  color: #0a4e97;
}
.cintac.darkBlueBackground {
  background-color: #0a4e97;
}

.cintac.orange {
  color: #e5530d;
}

.cintac.orangeBackground {
  background-color: #e5530d;
}

.cintac.lightOrangeBackground {
  background-color: #fef3d6;
}

.cintac.yellowBackground {
  background-color: #fac400;
}

.cintac.greenStar {
  color: #00b383;
}

.cintac.black {
  color: #222b45;
}

.cintac.noMargin {
  padding: 0px;
  margin: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
}

.cintac.roundButton {
  border-radius: 6px;
}

.cintacInvert {
  background-color: #0a4e97;
}
.cintacInvert p,
.cintacInvert p.cintac.hint,
.cintacInvert div,
.cintacInvert h6 {
  color: white;
}
