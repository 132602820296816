.container {
  background-color: #e5530d;
  border-radius: 6px;
  padding: 8px 16px;
  width: 130px;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
}

.InfoBlockTop {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: -16px;
  margin-top: -4px;
  margin-right: -6px;
}

.InfoBlockMiddle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.InfoBlockBottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.value {
  color: white;
  font-size: 26px;
}

.valueUnit {
  color: white;
  font-size: 12px;
  padding-bottom: 4px;
}

.type {
  color: white;
  font-size: 12px;
}
